<template>
  <section class="pb-4 container">
    <GoBack />
    <Breadcrumb class="sobre_section_bc-color">
      <template v-slot:breadcrumbLinks>
        <router-link
          :style="{
            color: color,
          }"
          tag="p"
          :to="{ name: link.href }"
          v-for="(link, idx) in breadcrumbItems"
          :key="idx"
        >
          {{ link.text }}
        </router-link>
      </template>
    </Breadcrumb>
    <v-row class="d-flex justify-center align-center mb-5">
      <div class="d-flex flex-column justify-center align-center mt-3 mb-3">
        <v-img
          max-width="40%"
          src="../../assets/assessoria_tecnica.png"
          alt=""
        />
      </div>
    </v-row>
    <v-row class="d-flex justify-center align-center mb-5">
      <v-col
        cols="12"
        sm="6"
        class="d-flex justify-center align-center mx-auto mb-3"
      >
        <div class="section__container_card">
          <Card borderColor="#C92F05" titleColor="#C92F05">
            <template v-slot:conteudo>
              <p>
                A Assessoria Técnica (AT) busca garantir a participação ampla e
                informada das pessoas atingidas nos processos decisórios e a
                efetivação plena da reparação das perdas e danos sofridos em
                decorrência do rompimento da barragem de rejeitos da Samarco
                (Vale e BHP), no município de Mariana-MG.
              </p>
              <p>
                Desde novembro de 2015, a luta pela Reparação Integral demanda
                grande esforço das famílias atingidas. É notória a necessidade
                da participação ativa dos atingidos e atingidas em uma série de
                processos decisórios que, ainda hoje, em alguns momentos, lhes é
                negada. A Assessoria Técnica da Cáritas busca, então, diminuir a
                assimetria técnica que existe entre as empresas mineradoras e a
                população atingida.
              </p>
              <p>
                O assessoramento teve início em outubro de 2016. Desde então, a
                Cáritas tem atuado fortemente ao lado das pessoas atingidas, em
                articulação com a Comissão de Atingidos pela Barragem de Fundão
                de Mariana (CABF). A AT é formada por profissionais das diversas
                áreas do conhecimento, tais como arquitetura, engenharias,
                serviço social, psicologia, comunicação e antropologia. Esta
                base transdisciplinar tem garantido o assessoramento das mais
                diversas demandas de reparação de famílias que tiveram seus
                modos de vida interrompidos pelo desastre-crime. Desse modo, a
                Cáritas MG tem fomentado auxílio e conhecimentos técnicos, de
                forma popular, para as pessoas atingidas, formando uma rede de
                agentes multiplicadores que se interligam e apoiam a luta por
                direitos.
              </p>
            </template>
          </Card>
        </div>
      </v-col>
      <v-col cols="11" sm="6">
        <div
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <v-img
            width="100%"
            src="../../assets/assembleia_comunidades_atingidas.png"
            alt=""
          />
          <p style="font-size: 0.5em">
            Assembléia das comunidades atingidas de Mariana. Foto: Ellen Barros,
            2019.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center align-center mb-5 flex-md-row-reverse">
      <v-col
        cols="12"
        sm="6"
        class="d-flex justify-center align-center mx-auto"
      >
        <div class="section__container_card">
          <Card
            title_alignment="center"
            borderColor="#C92F05"
            titleColor="#C92F05"
          >
            <template v-slot:conteudo>
              <p>
                A AT desenvolve uma comunicação clara e acessível com as
                comunidades atingidas, de modo a respeitar os aspectos culturais
                e circunstanciais de quem possui mais interesse na causa. Além
                disso, o levantamento de informações fornecidas pelas próprias
                pessoas atingidas é o ponto de partida da atuação técnica da
                Cáritas MG em Mariana. Nesse mesmo sentido, são disponibilizados
                aos atingidos e atingidas os relatórios, pareceres, notas
                técnicas e demais documentos elaborados pela AT e enviados ao
                Ministério Público de Minas Gerais, o que contribui para o
                melhor entendimento de todos os atores envolvidos nos diferentes
                contextos judiciais e extrajudiciais da reparação.
              </p>
              <p>
                Uma metodologia muito utilizada pela AT, e que abrange um maior
                número de pessoas atingidas, é a formação de grupos de base
                (GBs). O objetivo dos GBs é a promoção de espaços de encontro e
                fomento da organização coletiva das comunidades, através de
                reuniões com temas específicos. As reuniões da CABF, das quais a
                AT participa, também são uma importante ferramenta de
                comunicação e diálogo sobre o andamento do processo de reparação
                e sobre as demandas de atendimento das famílias e comunidades.
              </p>
              <p>
                Assim, o assessoramento técnico tem se consolidado como uma
                conquista das pessoas atingidas, elemento fundamental na disputa
                por uma reparação justa e integral.
              </p>
            </template>
          </Card>
        </div>
      </v-col>
      <v-col cols="11" sm="6">
        <div
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/grupo_assessoria_tecnica.png"
            alt=""
          />
          <p style="font-size: 0.5em">
            Assessoria Técnica. Foto: Wan Campos/Jornal A Sirene, 2017.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center mb-5">
      <div class="d-flex flex-column justify-center align-center">
        <v-btn
          :to="{ name: 'sobre_assessoria_juridica' }"
          small
          color="#E06919"
          class="white--text pa-5"
        >
          Assessoria Jurídica - AJ
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </v-row>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";
import GoBack from "@/components/GoBack.vue";

export default {
  components: { Card, Breadcrumb, GoBack },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Quem somos    /",
          href: "cards",
        },
        {
          text: "Assessoria Técnica /",
          href: "sobre_assessoria_tecnica",
        },
        {
          text: "Assessoria Técnica",
          href: "sobre_assessoria_tecnica",
        },
      ],
    };
  },
};
</script>

<style scoped>
h5 {
  color: #c92f05;
}

.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}

.section__container_card {
  max-width: 40em;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.assessoria_card {
  padding: 2em;
}

.assessoria_card p {
  padding: 1em;
  text-align: center;
  font-size: 0.7em;
}

.assessoria__items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 7em;
}

.assessoria__items p {
  text-align: center;
  font-size: 0.6em;
  max-width: 80%;
}
</style>
